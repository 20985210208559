import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  languageList:[], //语言列表
  L:[], //语言包
  nowLanguage:'',//当前的语言
  userInfo:{}, //用户信息
  cartCount:0, //购物车数量
  currency:'', //币种
  besideImgs:[], //右侧的小图片+文字
  bannerInfo:{}, //首页banner
  countryList:[] //国家列表
}
const actions = {
  changeLanguageList(context,obj){
    context.commit('changeLanguageList',obj)
  },
  changeLanguagePackage(context,obj){
    context.commit('changeLanguagePackage',obj)
  },
  setUserInfo(context,obj){
    context.commit('setUserInfo',obj)
  },
  setCurrency(context,val){
    context.commit('setCurrency',val)
  },
  setArea(context,val){
    context.commit('setArea',val)
  },
  setOU(context,val){
    context.commit('setOU',val)
  },
  clearUserInfo(context){
    context.commit('clearUserInfo')
  },
  setCartCount(context,num){
    context.commit('setCartCount',num)
  },
  setNowLanguage(context,val){
    context.commit('setNowLanguage',val)
  },
  setBesideImgs(context,arr){
    context.commit('setBesideImgs',arr)
  },
  setBannerInfo(context,obj){
    context.commit('setBannerInfo',obj)
  },
  setCountryList(context,arr){
    context.commit('setCountryList',arr)
  }
}
const mutations = {
  changeLanguageList(state,obj){
    state.languageList=obj
  },
  changeLanguagePackage(state,obj){
    state.L=obj
  },
  setUserInfo(state,obj){
    state.userInfo=obj
  },
  setCurrency(state,val){
    state.currency=val
  },
  setArea(state,val){
    state.area=val
  },
  setOU(state,val){
    state.ou=val
  },
  clearUserInfo(state){
    state.userInfo={}
  },
  setCartCount(state,num){
    state.cartCount=num
  },
  setNowLanguage(state,val){
    state.nowLanguage=val
  },
  setBesideImgs(state,arr){
    state.besideImgs=arr
  },
  setBannerInfo(state,obj){
    state.bannerInfo=obj
  },
  setCountryList(state,arr){
    state.countryList=arr
  }
}
export default new Vuex.Store({
  state,
  actions,
  mutations
})