<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap cart">
      <!-- 大警告 -->
      <div class="big-warning" v-if="showNotice">
        <span class="iconfont icon-warning">&#xe6aa;</span>
        <div class="text">
          <div>Due to an order block you cannot submit your order. You can add products to your cart and they will be stored, but no ordering is possible until the order block is removed.</div>
          <div>Please contact your local Customer Service representative.</div>
        </div>
      </div>
      <!-- 公告 -->
      <div class="main-wrap announcement" v-show="$store.state.bannerInfo.notice && $store.state.bannerInfo.notice.trim()">
        <span class="text">{{$store.state.bannerInfo.notice}}</span>
        <div class="left-line"></div>
        <div class="right-line"></div>
      </div>
      <!-- 数量大于0，或者，数量为0且是第四个步骤 -->
      <div v-show="$store.state.cartCount>0 || ($store.state.cartCount==0 && focusFlag==4)">
        <!-- 导航栏切换 -->
        <div class="tab">
          <div class="square-wrap" :class="{'square-wrap-active':focusFlag==1,'no-click':focusFlag<1}" @click="clickTopTab(1)">
            <div class="rule-square">
              <span class="text">
                <b>1.</b>
                <!-- 购物车总览 -->
                {{L.cartItems}}
              </span>
              <span class="right-triangle"></span>
            </div>
          </div>
          <div class="square-wrap" :class="{'square-wrap-active':focusFlag==2,'no-click':focusFlag<2 || focusFlag==4}" @click="clickTopTab(2)">
            <div class="rule-square">
              <span class="text">
                <b>2.</b>
                <!-- 查验交货日期和价格 -->
                  {{L.deliveryPriceCheck}}
              </span>
              <span class="left-triangle"></span>
              <span class="right-triangle"></span>
            </div>
          </div>
          <div class="square-wrap" :class="{'square-wrap-active':focusFlag==3,'no-click':focusFlag<3 || focusFlag==4}" @click="clickTopTab(3)">
            <div class="rule-square">
              <span class="text">
                <b>3.</b>
                <!-- 信息 -->
                {{L.information}}
              </span>
              <span class="left-triangle"></span>
              <span class="right-triangle"></span>
            </div>
          </div>
          <div class="square-wrap" :class="{'square-wrap-active':focusFlag==4,'no-click':focusFlag<4}" @click="clickTopTab(4)">
            <div class="rule-square">
              <span class="text">
                <b>4.</b>
                <!-- 结账 -->
                {{L.checkout}}
              </span>
              <span class="left-triangle"></span>
            </div>
          </div>
            
        </div>
        <!-- tab切换之后展现的内容 -->
        <Overview 
          ref="Overview"
          v-show="focusFlag==1" 
          @openLoading="openLoading" 
          @closeLoading="closeLoading" 
          @changeFocusFlag="changeFocusFlag" 
          @setDeliveryData="setDeliveryData"
          @getCartList="getCartList"
        />
        <Delivery 
          v-show="focusFlag==2" 
          @openLoading="openLoading" 
          @closeLoading="closeLoading" 
          @changeFocusFlag="changeFocusFlag" 
          @setAddressInfo="setAddressInfo" 
          :deliveryData="deliveryData"
        />
        <Info 
          v-show="focusFlag==3" 
          @openLoading="openLoading" 
          @closeLoading="closeLoading" 
          @changeFocusFlag="changeFocusFlag"
          @setOrderInfo="setOrderInfo"
          :addressInfo="addressInfo"
          :deliveryData="deliveryData"
        />
        <Checkout 
          v-show="focusFlag==4" 
          @openLoading="openLoading" 
          @closeLoading="closeLoading" 
          @changeFocusFlag="changeFocusFlag"
          :orderInfo="orderInfo"
          :orderList="orderList"
          :quoteOrderList="quoteOrderList"
        />
      </div>
      <!-- 数量为0并且不是第4个步骤 -->
      <div class="null" v-show="$store.state.cartCount==0 && focusFlag!=4">
        <!-- 我的购物车-标题 -->
        <div class="my-cart-title">
          <div class="title">{{L.myShoppingCart}}</div>
          <div class="btn-wrap">
            <!-- 快速订购 -->
            <div class="btn" @click="showQuickOrder">{{L.quickOrder}}</div>
          </div>
        </div>
        <!-- 警告 -->
        <div class="warning-wrap" v-if="cartListInfo.cartRemoveMsg">
          <span class="iconfont icon-warning">&#xe6aa;</span>
          <span class="warning-text">{{L.warning}}: {{cartListInfo.cartRemoveMsg}}</span>
        </div>
        <!-- 空的内容 -->
        <div class="content">
          <NoContent/>
          <!-- <div class="line">
            <span class="text2">Download Cart Template</span>
            <span  class="btn">Upload</span>
          </div> -->
        </div>
      </div>
    </div>

    <!-- quick order对话框 -->
    <QuickOrder ref="QuickOrder" @addRequest="addRequest"/>
    <AddCartErr ref="AddCartErr" />
    <AddCartSuccess ref="AddCartSuccess" />
    
  </div>
</template>

<script>
import Overview from './overview'
import Delivery from './delivery'
import Info from './info'
import Checkout from './checkout'
import NoContent from '@/components/NoContent'
import QuickOrder from '@/components/QuickOrder'
import AddCartErr from '@/components/AddCartErr'
import AddCartSuccess from '@/components/AddCartSuccess'
import {api_quick_order} from '@/api/api'
import {postAction} from '@/api/tool'

export default {
  name:'Catalogue',
  components:{
    Overview,
    Delivery,
    Info,
    Checkout,
    NoContent,
    QuickOrder,
    AddCartErr,
    AddCartSuccess
  },
  data(){
    return {
      // ouList:['OU-PL','OU-NL','OU-UK','OU-SK'], //这些OU的要展示公告
      focusFlag:1, //1购物车总览 2查验交货日期和价格 3完善采购信息 4结账
      cartListInfo:{}, //购物车第一步的数据（第一个页面请求完的购物车列表的数据传过来）
      deliveryData:{}, //购物车第二步的数据（第一个组件请求完的数据给第二个组件，所以通过父组件作为中介传递一下）
      addressInfo:{}, //购物车第三步的数据（第二个组件请求完的数据给第三个组件，所以通过父组件作为中介传递一下）
      orderInfo:{},//购物车第四步的数据（第三个组件请求完的数据给第四个组件，所以通过父组件作为中介传递一下）
      orderList:[],//购物车第四步的数据（第三个组件请求完的数据给第四个组件，所以通过父组件作为中介传递一下）
      quoteOrderList:[]//购物车第四步的数据（第三个组件请求完的数据给第四个组件，所以通过父组件作为中介传递一下）
    }
  },
  computed:{
    showNotice(){
      //最外层的判断是为了让includes方法不要报错，否则会报错的
      if(this.$store.state.userInfo.accountRules){
        if(this.$store.state.userInfo.accountRules.includes('OTN')){
          return false
        }else{
          if(this.$store.state.userInfo.accountRules.includes('CSB')){
            return false
          }else{
            return true
          }
        }
      }else{
        return false
      }
    }
  },
  methods:{
    /* 打开快速加入购物车 */
    showQuickOrder(){
      this.$refs.QuickOrder.showQuickOrder=true
    },
    /* 快速加入购物车,自组件触发本组件的这个方法 */
    addRequest(){
      let params={
        quickContent:this.$refs.QuickOrder.quickOrder
      }
      this.$refs.QuickOrder.loadingQuick=true
      postAction(api_quick_order,params).then(res=>{
        this.$refs.QuickOrder.loadingQuick=false
        //大于0说明是失败的，等于0说明是成功的
        if(res.length>0){
          //失败
          this.$refs.AddCartErr.open(res)
        }else{
          //成功
          this.$refs.AddCartSuccess.open()
          this.$refs.Overview.getCartList(1) //这个方法包含了获取购物车总数
          this.$refs.QuickOrder.closeQuickOrder()
        }
      }).catch(res=>{
        this.$refs.QuickOrder.loadingQuick=false
        this.$message.error(res.msg)
      })
    },
    /* 子组件打开loading触发这个方法 */
    openLoading(){
      this.allLoading=true
    },
    /* 子组件关闭loading触发这个方法 */
    closeLoading(){
      this.allLoading=false
    },
    changeFocusFlag(flag){
      this.focusFlag=flag
    },
    /* 
      要去的flag，小于当前聚焦的的flag，可以去点击的那个(即小的那个) 
      判断能去以后，再进一步判断：如果当前的flag是4，那么就不要去2或者3，只能去1
    */
    clickTopTab(flag){
      if(flag<this.focusFlag){
        if(this.focusFlag==4 && flag==3){
          return
        }
        if(this.focusFlag==4 && flag==2){
          return
        }
        if(flag==1){
          this.$refs.Overview.getCartList()
        }
        this.focusFlag=flag
      }
    },
    setDeliveryData(obj){
      this.deliveryData=obj
    },
    setAddressInfo(obj){
      this.addressInfo=obj.addressVo
    },
    setOrderInfo(obj){
      this.orderInfo=obj
      this.orderList=obj.orderItems
      console.log(obj,'objjjjj')
      this.quoteOrderList=obj.quoteOrderItems
    },
    getCartList(obj){
      this.cartListInfo=obj
    }
  },
  mounted(){
    this.deliveryData={}
    this.addressInfo={}
    this.orderInfo={}
    this.orderList=[]
    this.quoteOrderList=[]
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>

