import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import Storage from 'vue-ls'
import ElementUI from 'element-ui';
import VueCookies from 'vue-cookies'
// import 'element-ui/lib/theme-chalk/index.css';
import '../public/theme/index.css';

import locale from 'element-ui/lib/locale/lang/en'
import '../public/css/public.less'
import store from './store'
import mainMixins from './mixins/mainMixins'

Vue.config.productionTip = false
Vue.config.OSR_URL = 'https://www.osram.com/' //这个是用来切割下载链接的（familyData的下载，注意这里和vue.config.js的文件要一起改，否则可能会出错）
Vue.config.DAM_URL = 'https://damfiles.inventronicsglobal.com/' //这个是用来切割下载链接的（familyData的下载，注意这里和vue.config.js的文件要一起改，否则可能会出错）
Vue.config.YUNPAN_URL = 'https://files.inventronicsglobal.com:10081/' //这个是用来切割下载链接的（云盘文件的下载，注意这里和vue.config.js的文件要一起改，否则可能会出错）
Vue.use(VueRouter)
Vue.use(ElementUI, { locale })
Vue.use(VueCookies)
Vue.mixin(mainMixins)

//前端本地存储
Vue.use(Storage,{
  namespace: 'INV_ESHOP_', // key 键的前缀（随便起）
  name: 'ls', // 变量名称（随便起） 使用方式：Vue.ls.变量名称 或 this.$ls.变量名称
  storage: 'local' // 作用范围：local、session、memory
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
